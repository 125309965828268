import { APPLY_FDR_URL, APP_BASE_PATH } from '../../../../common/constants'
import { recommendedReadsData } from '../../consts'

const { whyWereBetter, whoWeHelp, faq } = recommendedReadsData

const TITLE = 'Freedom Debt Relief Reviews from Clients'
const DESCRIPTION =
  'Freedom Debt Relief has helped hundreds of thousands of clients to be debt free. Read and watch our client reviews and testimonials about our debt relief program.'
const URL = `${APP_BASE_PATH()}/testimonials/`

export const testimonialsData = {
  seoData: {
    title: TITLE,
    description: DESCRIPTION,
    canonical: URL,
    openGraph: {
      title: TITLE,
      description: DESCRIPTION,
      url: URL,
      type: 'article',
      locale: 'en_US',
      article: {
        modifiedTime: '2020-04-07T23:27:48+00:00',
        authors: ['https://www.facebook.com/freedomdebtrelief'],
      },
      images: [
        {
          url: `${APP_BASE_PATH()}/next-assets/brand-pages/heros/testimonials.webp`,
        },
      ],
    },
  },
  heroData: {
    title: 'Client reviews & testimonials',
    id: 'testimonialsHero',
    description: {
      nodeType: 'paragraph',
      content: [
        {
          nodeType: 'text',
          value:
            'Millions of others have been where you are. Our Freedom Debt Relief reviews highlight how our clients trusted us to help them find success.',
          marks: [],
        },
      ],
    },
    img: {
      src: '/next-assets/brand-pages/heros/testimonials.webp',
      alt: 'young male Freedom Debt Relief client',
    },
    provenResult: {
      list: [
        { quantity: '1 million+', tagline: 'clients served' },
        { quantity: '4 million+', tagline: 'accounts settled' },
        { quantity: '$18 billion+', tagline: 'in debt resolved' },
      ],
      showInMobile: false,
    },
  },
  clientTestimonialsData: {
    tagline: 'Client testimonials',
    title: 'Real stories from real clients',
    description: {
      nodeType: 'paragraph',
      data: {},
      content: [
        {
          nodeType: 'text',
          value:
            'From struggling with debt to living life again, the testimonials below\n show how our clients used Freedom Debt Relief to successfully reduce debt.',
          marks: [],
          data: {},
        },
      ],
    },
    hasLink: false,
    hasButton: false,
  },
  faqs: [
    {
      title: 'What is debt relief?',
      content: {
        nodeType: 'document',
        data: {},
        content: [
          {
            nodeType: 'paragraph',
            data: {},
            content: [
              {
                nodeType: 'text',
                value:
                  'The type of debt relief Freedom Debt Relief offers is known by several names: debt resolution, debt negotiation, and',
                marks: [],
                data: {},
              },
              {
                nodeType: 'hyperlink',
                data: {
                  uri: '/debt-solutions/debt-settlement/',
                  title: 'What is debt relief?',
                },
                content: [
                  {
                    nodeType: 'text',
                    value: ' debt settlement',
                    marks: [],
                    data: {},
                  },
                ],
              },
              {
                nodeType: 'text',
                value:
                  '. Debt relief allows you to resolve your unsecured debt (debt that is not backed by collateral like a car or a house; most often credit card debt) by negotiating with creditors and reducing the amount you owe. You could negotiate with your creditors on your own or use a',
                marks: [],
                data: {},
              },
              {
                nodeType: 'hyperlink',
                data: {
                  uri: '/debt-relief-program/',
                  title: 'What is debt relief?',
                },
                content: [
                  {
                    nodeType: 'text',
                    value: ' debt relief program ',
                    marks: [],
                    data: {},
                  },
                ],
              },
              {
                nodeType: 'text',
                value: 'like Freedom Debt Relief to help you settle your debt.',
                marks: [],
                data: {},
              },
            ],
          },
          {
            nodeType: 'paragraph',
            data: {},
            content: [
              {
                nodeType: 'text',
                value:
                  "During the debt relief process, you usually voluntarily stop paying your creditors and start saving money in a special purpose account (what Freedom Debt Relief calls your 'dedicated account') that is used to hold the funds used to settle your debt. Once enough money is saved into this account, the debt relief company you hired contacts your creditors to",
                marks: [],
                data: {},
              },
              {
                nodeType: 'hyperlink',
                data: {
                  uri: '/debt-solutions/how-to-negotiate/',
                },
                content: [
                  {
                    nodeType: 'text',
                    value: ' negotiate a debt settlement ',
                    marks: [],
                    data: {},
                  },
                ],
              },
              {
                nodeType: 'text',
                value: 'amount that is lower than what you currently owe.',
                marks: [],
                data: {},
              },
            ],
          },
        ],
      },
    },
    {
      title: 'What types of debt can Freedom Debt Relief help me with?',
      content: {
        nodeType: 'paragraph',
        data: {},
        content: [
          {
            nodeType: 'text',
            value:
              'Freedom Debt Relief could help you with debt from credit cards, medical bills, department store cards, and many other types of',
            marks: [],
            data: {},
          },
          {
            nodeType: 'hyperlink',
            data: {
              uri: '/debt-solutions/what-is-unsecured-debt/',
              title: 'What types of debt can Freedom Debt Relief help me with?',
            },
            content: [
              {
                nodeType: 'text',
                value: '  unsecured debt ',
                marks: [],
                data: {},
              },
            ],
          },
          {
            nodeType: 'text',
            value:
              '(debt that is not backed by collateral like a car or a house). Our program cannot help with secured debt, which is a debt that involves collateral (like auto loans and mortgages). Also, we cannot resolve federal student loans. We do help with private student loans and some business debts on a case-by-case basis.',
            marks: [],
            data: {},
          },
        ],
      },
    },
    {
      title: 'How much does debt relief cost?',
      content: {
        nodeType: 'document',
        data: {},
        content: [
          {
            nodeType: 'paragraph',
            data: {},
            content: [
              {
                nodeType: 'text',
                value:
                  "Across the industry, debt relief costs vary and are usually a percentage of the original amount of your debt or a percentage of the amount you've agreed to pay with the settlement.",
                marks: [],
                data: {},
              },
            ],
          },
          {
            nodeType: 'paragraph',
            data: {},
            content: [
              {
                nodeType: 'text',
                value:
                  'Freedom Debt Relief’s fee ranges between 15% to 25% of enrolled debt (the amount of debt enrolled to be settled)–your rate may vary depending on your state of residency and amount you enroll.',
                marks: [],
                data: {},
              },
            ],
          },
          {
            nodeType: 'paragraph',
            data: {},
            content: [
              {
                nodeType: 'text',
                value:
                  'In our program, clients pay FDR no fee whatsoever until a debt is negotiated, the settlement has been approved and the first payment has been made. You will know when a fee is charged because you will approve every settlement deal. Once we reach a settlement with a creditor, we immediately contact you for authorization. After you authorize a settlement and a payment has been made to your creditor, the fee associated with the debt is processed. The total fee associated with the program is included in the monthly savings quoted to you by a debt consultant.',
                marks: [],
                data: {},
              },
            ],
          },
        ],
      },
    },
    {
      title:
        'How is Freedom Debt Relief different from other debt relief options?',
      content: {
        nodeType: 'document',
        data: {},
        content: [
          {
            nodeType: 'paragraph',
            data: {},
            content: [
              {
                nodeType: 'text',
                value:
                  'Freedom Debt Relief is an established leader in debt resolution. Since 2002, over 1 million clients have been served by the Freedom Debt Relief program. We have resolved over $18 billion in debt. We have a team of committed experts working on your side: 500+ certified debt consultants, 200+ debt negotiators, and 300+ customer service representatives. We are an established employer with 1,300+ US employees in AZ, CA, and TX.',
                marks: [],
                data: {},
              },
            ],
          },
          {
            nodeType: 'paragraph',
            data: {},
            content: [
              {
                nodeType: 'text',
                value:
                  'Lastly, Freedom Debt Relief formally has a promise to customers in place to ensure that we deliver results to our customers. This promise is called the',
                marks: [],
                data: {},
              },
              {
                nodeType: 'hyperlink',
                data: {
                  uri: '/why-were-better/',
                },
                content: [
                  {
                    nodeType: 'text',
                    value: ' Freedom Debt Relief Promise ',
                    marks: [],
                    data: {},
                  },
                ],
              },
              {
                nodeType: 'text',
                value:
                  'and it assures that: 1. Each prospective customer has an opportunity to learn about their',
                marks: [],
                data: {},
              },
              {
                nodeType: 'hyperlink',
                data: {
                  uri: '/learn/debt-relief/debt-relief-options/',
                  title:
                    'How is Freedom Debt Relief different from other debt relief options?',
                },
                content: [
                  {
                    nodeType: 'text',
                    value: ' debt relief options ',
                    marks: [],
                    data: {},
                  },
                ],
              },
              {
                nodeType: 'text',
                value:
                  ', even those beyond our services. 2. Each customer receives a personalized assessment and recommendation of the course of action that offers the best opportunity for consumers to achieve their financial goals.  3. Each customer is fully informed at each step of the process. 4. Each customer has a Program Guarantee that Freedom Debt Relief succeeds only when our customers succeed. In addition to only charging fees when FDR delivers settlements, the Program Guarantee states that when a customer graduates or leaves the program, FDR will refund fees if their settlement and fees are more than the amount they originally owed when they enrolled in the program.',
                marks: [],
                data: {},
              },
            ],
          },
        ],
      },
    },
  ],
  realStoriesVideosData: [
    {
      tagline: 'Ozzy graduated from Freedom in 2020',
      title: 'Tools to take control of his debt',
      description: {
        nodeType: 'paragraph',
        data: {},
        content: [
          {
            nodeType: 'text',
            value:
              'Working with Ozzy through his online dashboard, Freedom Debt Relief was able to help him',
            marks: [],
            data: {},
          },
          {
            nodeType: 'hyperlink',
            data: {
              uri: '/debt-solutions/debt-settlement/',
              title: 'Tools to take control of his debt',
            },
            content: [
              {
                nodeType: 'text',
                value: ' settle ',
                marks: [],
                data: {},
              },
            ],
          },
          {
            nodeType: 'text',
            value: ' over $31,000 in debt in just a few years.',
            marks: [],
            data: {},
          },
        ],
      },
      videoImage: '/next-assets/brand-pages/client-stories/ozzy-video.webp',
      videoUrl: 'https://www.youtube.com/watch?v=NVxTO5Ea2-Y',
    },
    {
      tagline: 'Molly graduated from Freedom in 2019',
      title: 'Peace of mind from her team',
      description: {
        nodeType: 'paragraph',
        data: {},
        content: [
          {
            nodeType: 'text',
            value:
              'With a clear plan, Molly settled over $27,000 in debt with a monthly payment that was much less than what she was paying creditors.',
            marks: [],
            data: {},
          },
        ],
      },
      videoImage: '/next-assets/brand-pages/client-stories/molly-video.webp',
      videoUrl: 'https://www.youtube.com/watch?v=tMowVbnPpzk&feature=youtu.be',
    },
    {
      tagline: 'Patti graduated from Freedom in 2019',
      title: 'Freedom to invest in herself',
      description: {
        nodeType: 'paragraph',
        data: {},
        content: [
          {
            nodeType: 'text',
            value:
              'Patti’s team at Freedom Debt Relief was able to help remove her burden of $17,000 in debt. Now the money she was',
            marks: [],
            data: {},
          },
          {
            nodeType: 'hyperlink',
            data: {
              uri: '/credit-card-debt/',
              title: 'Freedom to invest in herself',
            },
            content: [
              {
                nodeType: 'text',
                value: ' paying to credit cards ',
                marks: [],
                data: {},
              },
            ],
          },
          {
            nodeType: 'text',
            value: ' is going to her savings and her future.',
            marks: [],
            data: {},
          },
        ],
      },
      videoImage: '/next-assets/brand-pages/client-stories/patti-video.webp',
      videoUrl: 'https://www.youtube.com/watch?v=s2FjhITmg0o',
    },
  ],
  freedomDebtStoryData: {
    tagline: '#myfreedomdebtstory',
    title: 'Freedom Debt Relief success stories',
    button: {
      name: 'Get debt help',
      href: APPLY_FDR_URL(),
    },
    isLeadId: true,
    description: {
      nodeType: 'paragraph',
      data: {},
      content: [
        {
          nodeType: 'text',
          value:
            "These reviews were filmed and voluntarily submitted by clients, telling their personal stories of success.\n *Actual clients. Clients' endorsements are paid testimonials. Individual results are not typical and will vary.",
          marks: [],
          data: {},
        },
      ],
    },
    videoList: [
      {
        img: {
          src: '/next-assets/brand-pages/client-stories/luis-video.webp',
          alt: 'Luis M',
        },
        videoLink: 'https://www.youtube.com/watch?v=Zwc-SrgOtu8',
        graduateDate: 2021,
        name: 'Luis M.',
        debt: '$60,834',
        settleNumber: 6,
        pronoun: 'his',
      },
      {
        img: {
          src: '/next-assets/brand-pages/client-stories/melissa-video.webp',
          alt: 'Melissa N',
        },
        videoLink: 'https://www.youtube.com/watch?v=9nN6hgBEgLM',
        graduateDate: 2020,
        name: 'Melissa N.',
        debt: '$47,591',
        settleNumber: 4,
        pronoun: 'her',
      },
      {
        img: {
          src: '/next-assets/brand-pages/client-stories/donna-video.web',
          alt: 'Donna H',
        },
        videoLink: 'https://www.youtube.com/watch?v=FG5kgoWtRTg',
        graduateDate: 2021,
        name: 'Donna H.',
        debt: '$33,284',
        settleNumber: 12,
        pronoun: 'her',
      },
    ],
  },
  recommendedReadsData: [whyWereBetter, whoWeHelp, faq],
  bottomSectionData: {
    title: `Discover your success with Freedom Debt Relief`,
    list: ['No upfront fees', 'No loan required', 'No more doing it alone'],
    image: {
      src: '/next-assets/brand-pages/client-stories/bottom-section-image.webp',
      alt: 'black female Freedom Debt Relief client',
    },
  },
}
